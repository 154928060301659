export const environment = {
  production: true,
  debug: false,
  apiRoot: 'api',
  info: {
    companyName: 'OÜ Vaha ja mesi',
    address: 'Mõisavahe tee 14, Jälgimäe küla Saku vald Harju maakond 76404',
    regNumber: 12916678,
    contactPhone: 53443065,
    contactEmail: 'info@meepood.ee',
  },
  sentry: 'https://a4051c34d21145deaa8778c4c8fe8cce@sentry.io/1463863',
  productImageRoot: 'http://4kie15rj.twic.pics/product/images',
  firebase: {
    name: 'ee-meepood-prod',
    functions: { domain: 'https://europe-west3-ee-meepood-prod.cloudfunctions.net' },
    options: {
      apiKey: 'AIzaSyAUvEwSQIWv6QX3RpN990pd6Bfbpkv7nhY',
      authDomain: 'ee-meepood-prod.firebaseapp.com',
      databaseURL: 'https://ee-meepood-prod.firebaseio.com',
      projectId: 'ee-meepood-prod',
      storageBucket: 'ee-meepood-prod.appspot.com',
      messagingSenderId: '531985201705',
      appId: '1:531985201705:web:d3d8c81694ef87ef5d7a38',
    },
  },
  analytics: { trackingId: 'UA-82104209-1' },
};
